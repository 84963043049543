<template>
  <TheWrapper>
    <h2 class="step__main-title">
      {{ $t('Choose a store location') }}
    </h2>
    <AppStoreSelector
      v-for="address in addresses"
      :key="address.id"
      v-bind="address"
      :selected="address.id === store"
      @change="change"
    />
    <UiButton
      mode="primary"
      class="main-button"
    >
      <button
        class="main-button__btn"
        :disabled="disableNext"
        :style="brandStyle"
        @click="apply"
      >
        <span>{{ $t('Apply') }}</span>
        <FontAwesomeIcon :icon="$options.faChevronRight" />
      </button>
    </UiButton>
  </TheWrapper>
</template>
<script>
import { APP_SET_STORE_LOCATION_ID, APP_SET_LOCATION_TYPE } from '../store/mutation.types'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import AppStoreSelector from '@/components/common/AppStoreSelector'
import TheWrapper from '@/components/layout/TheWrapper'

export default {
  name: 'ReturnsStoreLocation',
  faChevronRight,
  components: {
    AppStoreSelector,
    FontAwesomeIcon,
    TheWrapper,
    UiButton,
  },
  mixins: [brandColourMixin],
  data() {
    return {
      store: undefined,
    }
  },
  computed: {
    ...mapState({
      storeAddresses: (state) => state.storeAddresses,
    }),
    addresses() {
      return this.storeAddresses.map((address) => {
        return address
      })
    },
    disableNext() {
      return this.store === undefined
    },
  },
  methods: {
    change(_selected, id) {
      if (!id) { return }
      this.store = id
    },
    apply() {
      this.$store.commit(APP_SET_STORE_LOCATION_ID, this.store)
      this.$store.commit(APP_SET_LOCATION_TYPE, 'store')
      this.$router.push({ name: 'return-methods' }).catch(handleRouteRejection)
    },
  },
}
</script>
