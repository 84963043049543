<template>
  <ItemSelector
    :field-id="fieldId"
    group="return_reasons"
    :checked="selected"
    :visible-checkmark="true"
    @on-change="change"
  >
    <AddressFormatted v-bind="$props">
      <template #icon>
        <FontAwesomeIcon icon="store-alt" />
      </template>
    </AddressFormatted>
  </ItemSelector>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ItemSelector from './ItemSelector.vue'
import AddressFormatted from '@/components/common/AddressFormatted'

export default {
  name: 'AppStoreSelector',
  components: {
    FontAwesomeIcon,
    ItemSelector,
    AddressFormatted,
  },
  props: {
    id: { type: Number, required: true },
    company_name: { type: String, required: true },
    address_1: { type: String, required: true },
    house_number: { type: String, default: '' },
    postal_code: { type: String, default: '' },
    city: { type: String, required: true },
    country_name: { type: String, required: true },
    selected: { type: Boolean, default: false },
  },
  computed: {
    fieldId() {
      return `store-${this.id}`
    },
  },
  methods: {
    change(selected) {
      this.$emit('change', selected, this.id)
    },
  },
}
</script>
